
import {
  PriorityTypes,
  IssuePriority,
} from "@/store/modules/priority/priority.types";
import { Pagination } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { namespace } from "vuex-class";
import { commaDate, currency, pad, slash, dash, uuid } from "@/filters/utils";

const priorityX = namespace("Priority");

@Component({
  components: {
    IDatePicker: () => import("@/components/utils/IDatePicker.vue"),
    AddPriority: () => import("@/components/priority/AddPriority.vue"),
    EditPriority: () => import("@/components/priority/EditPriority.vue"),
    DeletePriority: () => import("@/components/priority/DeletePriority.vue"),
  },
  filters: {
    currency,
    slash,
    commaDate,
    pad,
    dash,
    uuid,
  },
})
export default class PrioritiesView extends Vue {
  @priorityX.Mutation(PriorityTypes.SET_ADD_PRIORITY_DIALOG)
  public setAddPriority!: (addPriority: boolean) => void;

  @priorityX.Mutation(PriorityTypes.SET_UPDATE_PRIORITY_DIALOG)
  public setUpdatePriority!: (updatePriority: boolean) => void;

  @priorityX.Mutation(PriorityTypes.SET_DELETE_PRIORITY_DIALOG)
  public setDeletePriority!: (deletePriority: boolean) => void;

  @priorityX.Mutation(PriorityTypes.SET_TICKET_PRIORITY_DATA)
  public setIssuePriorityData!: (ticketPriority: IssuePriority) => void;

  @priorityX.Action(PriorityTypes.LOAD_PRIORITIES)
  public getIssuePriorities!: (pagination: Pagination) => void;

  @priorityX.State(PriorityTypes.PRIORITIES)
  public ticketPriorities!: IssuePriority[];

  @priorityX.State(PriorityTypes.LOADING_PRIORITIES_STATE)
  public loadingPriorities!: boolean;

  @priorityX.State("addPriorityRef")
  public addPriorityRef!: number;

  openAddPriorityDialog(): void {
    this.setAddPriority(true);
  }

  public search = "";

  public headers: DataTableHeader[] = [
    {
      text: "#ID",
      sortable: false,
      value: "id",
      cellClass: "primary--text text--darken-2 font-weight-medium",
    },
    { text: "NAME", value: "name", cellClass: "grey--text text--darken-2" },
    {
      text: "DESCRIPTION",
      value: "description",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "STATUS",
      value: "active",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "CREATED",
      value: "createdAt",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
    {
      text: "ACTIONS",
      value: "actions",
      sortable: false,
      cellClass: "grey--text text--darken-2",
    },
  ];

  deletePriority(item: IssuePriority): void {
    this.setDeletePriority(true);
    this.setIssuePriorityData(item);
  }

  editPriority(item: IssuePriority): void {
    this.setUpdatePriority(true);
    this.setIssuePriorityData(item);
  }

  mounted(): void {
    this.getIssuePriorities({ page: 1, limit: 10 });
  }
}
